function Loading(props) {
  if (props.message) {
    return (
      <div className="d-flex align-items-center">
        <strong>{props.message}</strong>
        <div className="spinner-border ms-auto" role="status" aria-hidden="true"></div>
      </div>
    );

  } else {
    return (
      <div className="d-flex justify-content-center">
        <div className="spinner-border" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );
  }
};

export default Loading;
