import { Web3ReactProvider } from '@web3-react/core'
import Web3 from 'web3';

import MetamaskProvider from './components/MetamaskProvider';
import Header from './components/molecules/Header';
import Home from './components/pages/Home';

function getLibrary(provider, connector) {
  return new Web3(provider)
}

function App() {
  return (
    <Web3ReactProvider getLibrary={getLibrary}>
       <MetamaskProvider>
        <div className="container">
          <Header />
          <Home />
        </div>
      </MetamaskProvider>
    </Web3ReactProvider>
  );
}

export default App;
