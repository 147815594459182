import React, { useEffect, useState } from 'react';
import { useWeb3React } from '@web3-react/core';

import SmartContract from '../../contracts/EmergeLogoNFT.json';

function OwnerNFTs() {
  const [contract, setContract] = useState(false);
  const [ownerNFTs, setOwnerNFTs] = useState([]);
  const contractABI = SmartContract.abi;
  const contractAddress = process.env.REACT_APP_NFT_CONTRACT_ADDR;
  const targetChainId = parseInt(process.env.REACT_APP_TARGET_CHAIN_ID);

  const { chainId, account, active, library } = useWeb3React();

  // shortcut helper to know if user has signed into metamask and is on right network
  const isConnected = (chainId === targetChainId && active);

  const loadContract = () => {
    if (!contract && active) {
      const newContract = new library.eth.Contract(contractABI, contractAddress);
      setContract(newContract);
    }
  };

  const getOwnerNFTs = async() => {
    if (contract && isConnected) {
      // this returns the count of tokens owned by an address
      const tokenCount = await contract.methods.balanceOf(account).call();
      let nfts = [];
      if (tokenCount) {
        for (let i = 0; i < tokenCount; i++) {
          // this should return the tokenId of each token owned by an address
          const tokenId = await contract.methods.tokenOfOwnerByIndex(account, i).call();
          if (tokenId && parseInt(tokenId) > 0) {
            nfts.push({
              "tokenId": tokenId,
              "imageSrc": process.env.REACT_APP_IPFS_GATEWAY_BASE_URL + tokenId + process.env.REACT_APP_IMAGE_FILE_EXT,
              "openSeaLink": process.env.REACT_APP_OPEN_SEA_BASE_URL + tokenId
            });
          }
        }
      }
      setOwnerNFTs(nfts);
    }
  };

  useEffect(() => {
    loadContract();
    getOwnerNFTs();
  }, [account, active, contract]);

  let nftItems = (<p>None</p>);
  if (ownerNFTs && ownerNFTs.length) {
    nftItems = ownerNFTs.map((nft) =>
      <div key={nft.tokenId}><a href={nft.openSeaLink} target="_blank" rel="noreferrer"><img src={nft.imageSrc} alt="EmergePunk" className="owned-emerge-punk-img px-3 py-1" /></a></div>
    );
  }

  if (!ownerNFTs || ownerNFTs.length === 0) {
    return (<></>);
  }

  return (
    <div className="container px-4 mt-mb-4">
        <div className="row mb-5">
          <div className="col">
            <div className="row mb-4">
              <div className="col-lg-7 col-12">
                <h2 className="d-flex">YOUR EMERGEPUNKS</h2>
                <p>
                  Looks like you've already minted {(nftItems.length > 1) ? 'some EmergePunks' : 'an EmergePunk'}!  Here's what you got!
                </p>
              </div>
            </div>
            <div className="row mb-4">
              <div className="mb-4 col-12 d-flex justify-content-left flex-wrap">
                {nftItems}
              </div>
            </div>
          </div>
        </div>
      </div>
  );
}

export default OwnerNFTs;
