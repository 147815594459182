import React, {useEffect, useState} from 'react';
import { useWeb3React } from '@web3-react/core';

import { injected } from '../../connectors/connectors';
import ViewNFTButton from "../atoms/ViewNFTButton";
import Loading from "../atoms/Loading";

function QuickStartBanner(props) {
  const [requestingFunds, setRequestingFunds] = useState(false);
  const [error, setError] = useState();
  const [accountValid, setAccountValid] = useState(false);
  const [transactionHash, setTransactionHash] = useState();
  const { chainId, activate, account, library } = useWeb3React();

  const blockExplorerUrl = process.env.REACT_APP_BLOCK_EXPLORER_URL;

  useEffect(() => {
    if (account) {
      checkWalletFunds();
    }
  }, [account]);

  const checkWalletFunds = async() => {
    library.eth.getBalance(account).then((value => {
      const val = parseInt(value);
      if (val === 0) {
        setAccountValid(true);
      } else {
        setAccountValid(false);
      }
    }));
  }

  const requestFaucetFunds = async () => {
    activate(injected);

    setRequestingFunds(true);
    setError()

    let success = false;
    fetch(process.env.REACT_APP_FAUCET_API_URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({address: account}),
    })
    .then((response) => {
      console.log(response.status);
      if (response.status === 200) {
        success = true;
        setAccountValid(false);
      }
      setRequestingFunds(false);
      return response.json()
    })
    .then(data => {
      if (success) {
        const messageParts = data.message.split(" ");
        const transactionHash = messageParts[2];
        setTransactionHash(transactionHash);
      } else {
        setError(data.message)
      }
    })
    .catch((error) => {
      console.debug(error);
      setError(error);
    });
  };

  const addPolygonToWallet = async () => {
    activate(injected);

    if (chainId !== 137) {
      window.ethereum.request({
        method: 'wallet_addEthereumChain',
        params: [{
          chainId: '0x89',
          chainName: 'Polygon Mainnet',
          nativeCurrency: {
            name: 'Matic Token',
            symbol: 'MATIC',
            decimals: 18
          },
          rpcUrls: ['https://polygon-rpc.com/'],
          blockExplorerUrls: ['https://polygonscan.com']
        }]
      })
    }
  };

  let message = (<p>Receive MATIC from our faucet to pay for gas fees. This will only work if you have no MATIC in your wallet.</p>);
  let cta;

  if (requestingFunds) {
    message = (<div className="mt-1 alert alert-secondary col-11" role="alert"><Loading message="Transferring..." /></div>);
  } else if (transactionHash) {
    message = (<div className="mt-1 alert alert-emerge-blue col-11">MATIC has been deposited to your account! Note that it may take a minute or two for the funds to show up in your wallet.<a href={`${blockExplorerUrl}${transactionHash}`} target="_blank" rel="noreferrer">(view transaction)</a></div>);
  } else if (error) {
    message = (<div className="mt-1 alert alert-danger col-11">{error}</div>);
  }

  if (accountValid) {
    cta = (<button type="button" className="btn btn-outline-emerge-blue" onClick={requestFaucetFunds} disabled={requestingFunds}>RECEIVE MATIC</button>);
  } else if (!account) {
    cta = (<button type="button" className="btn btn-outline-emerge-blue" disabled={true}>RECEIVE MATIC</button>);
  } else {
    cta = (<button type="button" className="btn btn-outline-emerge-blue" disabled={true}>ACCOUNT ALREADY FUNDED</button>);
  }

  return (
    <div className="mb-3 col-lg-12 col-12">
      <h2>QUICK START GUIDE: HOW TO GET AN EMERGEPUNK</h2>
      <p>
        If you are new to collecting NFTs, here is how to get started. We recommend doing this from a personal machine that you own.
      </p>
      <div className="card quickstart-card mb-3">
        <div className="row g-0">
          <div className="col-md-1">
            <div className="vh-70 align-items-center justify-content-center">
              <div className="quickstart-number align-items-center justify-content-center">
                <div>1</div>
              </div>
            </div>
          </div>
          <div className="col-md-8 align-items-center d-flex">
            <div className="card-body">
              <p>Download and set up your MetaMask wallet. Be sure to safely record your recovery phrase! You’ll need it to access your wallet in the future.</p>
            </div>
          </div>
          <div className="col-md-2">
            <a className="btn btn-outline-emerge-blue" href="https://metamask.io/download.html" target="_blank" rel="noreferrer">DOWNLOAD METAMASK</a>
          </div>
        </div>
      </div>
      <div className="card quickstart-card mb-3">
        <div className="row g-0">
          <div className="col-md-1">
            <div className="vh-70 align-items-center justify-content-center">
              <div className="quickstart-number align-items-center justify-content-center">
                <div>2</div>
              </div>
            </div>
          </div>
          <div className="col-md-8 align-items-center d-flex">
            <div className="card-body">
              <p>Connect your wallet and add the Polygon network to MetaMask.</p>
            </div>
          </div>
          <div className="col-md-2">
            <button className="btn btn-outline-emerge-blue" onClick={addPolygonToWallet}>CONNECT & ADD POLYGON</button>
          </div>
        </div>
      </div>
      <div className="card quickstart-card mb-3">
        <div className="row g-0">
          <div className="col-md-1">
            <div className="vh-70 align-items-center justify-content-center">
              <div className="quickstart-number align-items-center justify-content-center">
                <div>3</div>
              </div>
            </div>
          </div>
          <div className="col-md-8 align-items-center d-flex">
            <div className="card-body">
              {message}
            </div>
          </div>
          <div className="col-md-2">
            {cta}
          </div>
        </div>
      </div>
      <div className="card quickstart-card mb-3">
        <div className="row g-0">
          <div className="col-md-1">
            <div className="vh-70 align-items-center justify-content-center">
              <div className="quickstart-number align-items-center justify-content-center">
                <div>4</div>
              </div>
            </div>
          </div>
          <div className="col-md-8 align-items-center d-flex">
            <div className="card-body">
              <p>Mint your EmergePunk. Only one EmergePunk mint allowed at a time.</p>
            </div>
          </div>
          <div className="col-md-2">
            {/* <button className="btn btn-outline-emerge-blue" onClick={props.modalOpenHandler} disabled={(props.tokenId)}>MINT NOW</button> */}
            <button className="btn btn-outline-emerge-blue" onClick={props.modalOpenHandler} disabled={true}>MINT NOW</button>
          </div>
        </div>
      </div>
      <div className="card quickstart-card mb-3">
        <div className="row g-0">
          <div className="col-md-1">
            <div className="vh-70 align-items-center justify-content-center">
              <div className="quickstart-number align-items-center justify-content-center">
                <div>5</div>
              </div>
            </div>
          </div>
          <div className="col-md-8 align-items-center d-flex">
            <div className="card-body">
              <p>View your freshly minted EmergePunk on OpenSea. It will take a few minutes after minting until your NFT is visible on OpenSea.</p>
            </div>
          </div>
          <div className="col-md-2">
            {props.tokenId && (
              <ViewNFTButton tokenId={props.tokenId} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default QuickStartBanner;
