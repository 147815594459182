function Footer() {
  return (
    <div className="mb-3 col-lg-12 col-12" id="footer">
      <hr />
      <p>&copy; {new Date().getFullYear()} Emerge Interactive</p>
    </div>
  );
}

export default Footer;
