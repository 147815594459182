import React, { useEffect, useImperativeHandle, useState, forwardRef, useCallback } from 'react';
import { createPortal } from 'react-dom';
// import '../../themes/modal.css';
// import '../../themes/modal2.css';

const modalElement = document.getElementById('modal-root')

export function Modal({ children, fade = false, defaultOpened = false }, ref) {
  const [isOpen, setIsOpen] = useState(false)

  const close = useCallback(() => setIsOpen(false), [])

  useImperativeHandle(ref, () => ({
    open: () => setIsOpen(true),
    close
  }), [close])

  const handleEscape = useCallback(event => {
    if (event.keyCode === 27) close()
  }, [close])

  useEffect(() => {
    if (isOpen) document.addEventListener('keydown', handleEscape, false)
    return () => {
      document.removeEventListener('keydown', handleEscape, false)
    }
  }, [handleEscape, isOpen])

  return createPortal(
    isOpen ? (
      <div className={`modal fade react-modal ${(isOpen) ? 'show': ''}`}>
        <div className="modal-overlay" />
        <div className="modal-dialog">
          <div className="modal-content bg-black">
            <div className="modal-header mx-auto bg-black">
              <h4 className="modal-title text-white">Mint an EmergePunk</h4>
              <button type="button" className="btn-close btn-close-white" id="mint-modal-close-btn" aria-label="Close" onClick={close}></button>
            </div>
            <div className="modal-body text-center bg-black text-white">
              {children}
            </div>
          </div>
        </div>
      </div>
      // <div className={`custom-modal ${fade ? 'modal-fade' : ''}`}>
      //   <div className="modal-overlay" onClick={close} />
      //   <span role="button" className="modal-close" aria-label="close" onClick={close}>
      //     x
      //   </span>
      //   <div className="custom-modal-body">{children}</div>
      // </div>
    ) : null,
    modalElement
  )
}

export default forwardRef(Modal)
