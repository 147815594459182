import { useState, useEffect, useRef } from 'react';

import HeroBanner from '../molecules/HeroBanner';
import WelcomeBanner from '../molecules/WelcomeBanner';
import MintBanner from '../molecules/MintBanner';
import RarityBanner from '../molecules/RarityBanner';
import QuickStartBanner from '../molecules/QuickStartBanner';
import AboutBanner from '../molecules/AboutBanner';
import Footer from '../molecules/Footer';
import Modal from '../organisms/Modal';
import MintInterface from "../organisms/MintInterface";
import OwnerNFTs from '../molecules/OwnerNFTs';

function Home() {
  const mintModal = useRef(null);

  const [tokenId, setTokenId] = useState();

  useEffect(() => {
    setTokenId(JSON.parse(window.localStorage.getItem('tokenId')));
  }, []);

  useEffect(() => {
    if(tokenId) {
      window.localStorage.setItem('tokenId', tokenId);
    }
  }, [tokenId]);

  const mintButtonOpenHandler = () => {
    mintModal.current.open();
  };

  return (
    <div className="main-container">
      <HeroBanner />
      <div className="container px-4 mt-mb-4">
        <div className="row mb-5">
          <div className="col">
            <WelcomeBanner />
          </div>
        </div>
      </div>
      <OwnerNFTs />
      <div className="container px-4 mt-mb-4">
        <div className="row mb-5">
          <div className="col">
            <MintBanner modal={mintModal} modalOpenHandler={mintButtonOpenHandler} tokenId={tokenId} />
          </div>
        </div>
      </div>
      <div className="container px-4 mt-mb-4">
        <div className="row mb-5">
          <div className="col">
            <RarityBanner />
          </div>
        </div>
      </div>
      <div className="container px-4 mt-mb-4">
        <div className="row mb-5">
          <div className="col">
            <QuickStartBanner modal={mintModal} modalOpenHandler={mintButtonOpenHandler} tokenId={tokenId} />
          </div>
        </div>
      </div>
      <div className="container px-4 mt-mb-4">
        <div className="row mb-5">
          <div className="col">
            <AboutBanner />
          </div>
        </div>
      </div>
      <div className="container px-4 mt-mb-4">
        <div className="row mb-5">
          <div className="col">
            <Footer />
          </div>
        </div>
      </div>
      <Modal ref={mintModal}>
        <MintInterface tokenId={tokenId} setTokenId={setTokenId} />
      </Modal>
    </div>
  );
}

export default Home;
