import heroImage from '../../media/header.jpg';

function HeroBanner() {
  return (
    <div className="container mb-4 mb-lg-5">
      <div className="row">
        <div className="px-0 col-12">
           <img src={heroImage} className="img-fluid px-0" alt="EmergePunks collage" />
        </div>
      </div>
    </div>
  );
}

export default HeroBanner;
