import React, { useEffect, useState } from 'react';
import { useWeb3React } from '@web3-react/core';

import { injected } from '../../connectors/connectors';
import SmartContract from '../../contracts/EmergeLogoNFT.json';
import punkImage from '../../media/mint-popup-punk-animation.gif';

import Loading from '../atoms/Loading';
import ViewNFTButton from '../atoms/ViewNFTButton';

function MintInterface(props) {
  const [contract, setContract] = useState(false);
  const [maxSupply, setMaxSupply] = useState();
  const [totalSupply, setTotalSupply] = useState();
  const [claimingNFT, setClaimingNFT] = useState(false);
  const [error, setError] = useState();
  const [balance, setBalance] = useState();
  const { chainId, account, activate, active, library } = useWeb3React();

  const contractABI = SmartContract.abi;
  const contractAddress = process.env.REACT_APP_NFT_CONTRACT_ADDR;
  const targetChainId = parseInt(process.env.REACT_APP_TARGET_CHAIN_ID);
  const gasPrice = parseInt(process.env.REACT_APP_GAS_PRICE);

  // shortcut helper to know if user has signed into metamask and is on right network
  const isConnected = (chainId === targetChainId && active);

  const handleConnect = () => {
    activate(injected);
  }

  const loadContract = () => {
    if (!contract && active) {
      const newContract = new library.eth.Contract(contractABI, contractAddress);
      setContract(newContract);
    }
  };

  const getMaxSupply = async() => {
    if (contract && isConnected) {
      const maxSupply = await contract.methods.maxSupply().call();
      setMaxSupply(parseInt(maxSupply));
    }
  };

  const getTotalSupply = async() => {
    if (contract && isConnected) {
      const totalSupply = await contract.methods.totalSupply().call();
      setTotalSupply(parseInt(totalSupply));
    }
  };

  const getAccountBalance = async () => {
    const balance = await library.eth.getBalance(account);
    setBalance(library.utils.fromWei(balance));
  };

  const handleMint = async () => {
    setClaimingNFT(true);
    setError();
    if (contract && isConnected) {
      try {
        const gas = await contract.methods.mint(account).estimateGas();
        await contract.methods
          .mint(account)
          .send({
            from: account,
            gas: gas,
            gasPrice: gasPrice
          })
          .on('receipt', function(receipt){
            console.debug('receipt', receipt);
            // try to pull out the token Id that just got minted. we should
            // be able to append this to an opensea link
            let thisTokenId;
            try {
              console.debug('return values', receipt.events.Transfer.returnValues);
              thisTokenId  = receipt.events.Transfer.returnValues.tokenId;
              props.setTokenId(thisTokenId);
            } catch (error) {
              console.debug('error', error);
              setError('😥 Mint complete, but could not fetch tokenId: ' + error.message);
            }
          })
          .on('error', function(error, receipt) {
            console.debug('error', error);
            console.debug('error receipt', receipt);
            setError('😥 Mint failed: ' + error.message);
          });

      } catch (error) {
        setError('😥 ' + error.message);
      }
    }
    setClaimingNFT(false);
  };

  useEffect(() => {
    loadContract();
    getMaxSupply();
    getTotalSupply();
  });

  useEffect(() => {
    if (account) {
      getAccountBalance();
    }
  }, [account]);

  let message;
  let cta;

  // determine content for message area will display
  if (account && chainId !== targetChainId) {
    message = (<div className="m-3 alert alert-warning">Please open MetaMask and connect to {process.env.REACT_APP_NETWORK_NAME} network!</div>);
  } else if (totalSupply >= maxSupply) {
    message = (<div className="m-3 alert alert-warning">Sorry, you are too late!  The EmergePunks have all been minted.  View the collection on <a href="https://opensea.io/collection/emergepunk" target="_blank" rel="noreferrer">OpenSea</a>.</div>);
  } else if (account && chainId === targetChainId && !props.tokenId && balance <= 0) {
    message = (<div className="m-3 alert alert-danger">You do not have enough {process.env.REACT_APP_NETWORK_TOKEN_NAME} in your wallet to mint an NFT.  Please close the modal and grab some funds from our faucet!</div>);
  } else if (claimingNFT) {
    message = (<div className="m-3 alert alert-secondary" role="alert"><Loading message="Minting NFT..." /></div>);
  } else if (props.tokenId) {
    message = (
      <div className="m-3 alert alert-emerge-blue">
        Congrats! You are now the proud owner of an EmergePunk! Go check it out on OpenSea using the button below (it will take a few minutes before your NFT is available on OpenSea).
        <div className="mt-3">
          <a href={process.env.REACT_APP_OPEN_SEA_BASE_URL + props.tokenId} target="_blank" rel="noreferrer"><img src={process.env.REACT_APP_IPFS_GATEWAY_BASE_URL + props.tokenId + process.env.REACT_APP_IMAGE_FILE_EXT} alt="EmergePunk" className="owned-emerge-punk-img owned-emerge-punk-img-blue px-3 py-1" /></a>
        </div>
      </div>
    );
  } else if (error) {
    message = (<div className="m-3 alert alert-danger">{error}</div>);
  }

  // determine what the CTA area will display
  if (!account) {
    cta = (<button type="button" className="btn btn-outline-warning" onClick={handleConnect}>Connect MetaMask</button>);
  } else if (account && chainId === targetChainId && !props.tokenId && balance > 0 && !claimingNFT) {
    cta = (<button type="button" className="btn btn-outline-emerge-blue-black btn-mint-lg" onClick={handleMint} disabled={(claimingNFT || totalSupply >= maxSupply)}>MINT</button>);
  } else if (props.tokenId) {
    // cta = (<a className="btn btn-success" href={process.env.REACT_APP_OPEN_SEA_BASE_URL + props.tokenId} target="_blank" rel="noreferrer">VIEW NFT</a>);
    cta = (<ViewNFTButton tokenId={props.tokenId} />);
  }

  return (
    <div className="row">
      <div className="col-12 pb-2">
        <img src={punkImage} alt="EmergePunk" className="img-fluid" id="img-mint-emergepunk" />
      </div>
      <div className="col-12">
        {maxSupply && (
          <p className="p1">{totalSupply}/{maxSupply}</p>
        )}
        <p className="p1">
          Mint your EmergePunk for free! Just pay gas for the transaction (available from our faucet).
        </p>
        {message}
        {cta}
        <p className="m-3 fw-light font-size-sm">
          Please make sure you are connected to the {process.env.REACT_APP_NETWORK_NAME} with correct address. Note: Once you make the purchase, you cannot undo this action.
          {/* <br /><br />We have set the gas limit to 3,000,000 for the contract to mint successfully. */}
        </p>
      </div>
    </div>
  );
}

export default MintInterface;
