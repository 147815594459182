import twitterLogo from '../../media/twitter.png';
import logo from '../../media/emerge_logo_inverted.jpg';

function Header() {
  return (
    <nav id="nav" className="navbar navbar-dark bg-black navbar-expand-md sticky-top pt-2">
      <a className="navbar-brand" href="https://emergeinteractive.com"><img src={logo} alt="emerge-logo" id="header-emerge-logo" width="auto" /></a>
      <div className="collapse navbar-collapse" id="navbarScroll">
        <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
          <li className="nav-item">
            <a className="nav-link" href="https://www.twitter.com/emergeinteract/" target="_blank" rel="noreferrer"><img className="img-fluid header-socials-logo" src={twitterLogo} alt="Twitter logo" /></a>
          </li>
        </ul>
      </div>
    </nav>
  );
}

export default Header;
