import exoticImage from '../../media/exotic.png';
import rareImage from '../../media/rare.png';
import legendaryImage from '../../media/legendary.png';
import commonImage from '../../media/common.png';
import uncommonImage from '../../media/uncommon.png';

function RarityBanner() {
  return (
    <div className="mb-3 col-lg-12 col-12">
      <div className="row">
        <div className="col-lg-5 col-12">
          <h2>RARITY OF EMERGEPUNKS</h2>
          <p>
            The rarity of a particular EmergePunk is determined by the different traits. There are 6 different types of EmergePunks with distinct traits grouped under 5 rarity categories: 1. Exotic, 2. Legendary, 3. Rare, 4. Uncommon, and 5. Common.
          </p>
        </div>
        <div className="col-lg-7 col-6 d-flex justify-content-end">
          <div className="card-group rarity-card-group">
            <div className="card rarity-card text-center">
              <img src={exoticImage} className="card-img-top rarity-img" alt="rarity: exotic" />
              <div className="card-body">
                <p className="card-text">Exotic</p>
              </div>
            </div>
            <div className="card rarity-card text-center">
              <img src={legendaryImage} className="card-img-top rarity-img" alt="rarity: legendary" />
              <div className="card-body">
                <p className="card-text">Legendary</p>
              </div>
            </div>
            <div className="card rarity-card text-center">
              <img src={rareImage} className="card-img-top rarity-img" alt="rarity: rare" />
              <div className="card-body">
                <p className="card-text">Rare</p>
              </div>
            </div>
            <div className="card rarity-card text-center">
              <img src={uncommonImage} className="card-img-top rarity-img" alt="rarity: uncommon" />
              <div className="card-body">
                <p className="card-text">Uncommon</p>
              </div>
            </div>
            <div className="card rarity-card text-center">
              <img src={commonImage} className="card-img-top rarity-img" alt="rarity: common" />
              <div className="card-body">
                <p className="card-text">Common</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RarityBanner;
