import welcomeImage from '../../media/welcome.png';

function WelcomeBanner() {
  return (
    <div className="row mb-4">
      <div className="mb-4 col-lg-7 col-12">
        <h2 className="d-flex">WELCOME TO THE <br /> EMERGEPUNKS CLUB</h2>
        <p>
          EmergePunks is a collection of {process.env.REACT_APP_NFT_TOTAL_SUPPLY} adorable Emerge logos with different traits. Each EmergePunk is unique and algorithmically generated from over 60 possible traits, such as headwear, eye wear, ornaments, and more. All EmergePunks are eccentric, but some are more exotic and legendary than others.
        </p>
        <p>
          EmergePunks are stored as ERC-721 tokens (NFTs) on the Polygon blockchain and their metadata is hosted on IPFS. Minting an unique EmergePunk is virtually free; all you have to pay is the gas fee for the transaction on Polygon. Once you mint an EmergePunk, it is yours forever (unless you sell it).
        </p>
      </div>
      <div className="my-lg-auto col-lg-4 col-12 offset-lg-1">
        <div className="common-container">
          <div className="row">
            <img src={welcomeImage} className="img-fluid px-0" alt="EmergePunks" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default WelcomeBanner;
