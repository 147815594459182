function AboutBanner() {
  return (
    <div className="mb-3 col-lg-12 col-12">
      <h2>WHAT IS THIS ABOUT?</h2>
      <p>We are launching this project for fun and to contribute to the NFT collectible ecosystem. Unlike most other NFT projects, we are giving away our NFTs for free. Who knows, they might be worth something in the future, so hold on to yours tightly! If you want to learn more about what NFTs are and how they work, <a href="https://www.theverge.com/22310188/nft-explainer-what-is-blockchain-crypto-art-faq" target="_blank" rel="noreferrer">here’s a good article</a> by The Verge.</p>
    </div>
  );
}

export default AboutBanner;
