import ViewNFTButton from "../atoms/ViewNFTButton";

function MintBanner(props) {

  let mintCta;
  let mintMessage;

  mintCta = (<ViewNFTButton tokenId={props.tokenId} btnClass="btn-outline-emerge-blue-black" />);
  mintMessage = (<p>Sorry, you are too late!  The EmergePunks have all been minted.  View the collection on <a href="https://opensea.io/collection/emergepunk" target="_blank" rel="noreferrer">OpenSea</a>.</p>);
  /* drc (5/27/2022) - commenting these out as all NFTs have been minted
  if (props.tokenId) {
    mintMessage = "You've already minted your EmergePunk.  Go check it out on OpenSea!";
    mintCta = (<ViewNFTButton tokenId={props.tokenId} btnClass="btn-outline-emerge-blue-black" />);
  } else {
    mintMessage = `Minting your own EmergePunk only takes seconds. If you don’t have a MetaMask wallet set up on ${process.env.REACT_APP_NETWORK_NAME}, take a look at our quick start guide below.`;
    mintCta = (<button className="btn btn-outline-emerge-blue-black btn-mint-lg" onClick={props.modalOpenHandler} disabled={(props.tokenId) ? true : false}>MINT</button>);
  }
  */

  return (
    <div id="buy-nft" className="buy-token-container bg-emerge-blue text-black">
      <div className="row p-5 m-auto">
        <div className="my-auto col-lg-8 col-12">
          <h2>MINT YOUR EMERGEPUNK</h2>
          <p className="mb-lg-0">{mintMessage}</p>
        </div>
        <div className="m-auto col-lg-4 col-12 offset-lg-1">
          <div className="text-center">
            {mintCta}
          </div>
        </div>
      </div>
    </div>
  );
}

export default MintBanner;
